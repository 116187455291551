import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Button from "../components/button"
import { colors } from "../utils/siteVars"
import { graphql, Link, useStaticQuery } from "gatsby"
import FullScreenText from "../components/fullScreenText"

const StyledIndexPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 55px - 2rem);
  /* h1 {
    font-size: 6rem;
    span {
      margin-left: 5rem;
    }
    margin: 1rem;
  }
  .get-started-button {
    text-align: center;
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 3rem;
      span {
        margin-left: 2rem;
      }
      margin: initial;
    }
  } */
`

const IndexPage = () => {
  const query = useStaticQuery(graphql`
    query indexQuery {
      bgImg: file(relativePath: { eq: "bg_index.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const c = "rgba(30,50,100,0.8)"
  const layeredBG = [
    `linear-gradient(${c}, ${c})`,
    query.bgImg.childImageSharp.fluid,
  ]

  return (
    <Layout title="Design your tshirt" bg={layeredBG}>
      <StyledIndexPage>
        <FullScreenText>
          <h1>
            Design your
            <br />
            <span>t-shirt today.</span>
          </h1>
          <div className="button-container">
            <Link to="make">
              <Button bg={colors.secondary} color={colors.dark}>
                Get started
              </Button>
            </Link>
          </div>
        </FullScreenText>
      </StyledIndexPage>
    </Layout>
  )
}

export default IndexPage
