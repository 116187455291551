import React from "react"
import styled from "styled-components"

const StyledFullScreenText = styled.div`
  h1 {
    font-size: 6rem;
    span {
      margin-left: 5rem;
    }
    margin: 1rem;
  }
  .button-container {
    text-align: center;
  }
  p {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    font-weight: 300;
  }
  @media (max-width: 768px) {
    h1 {
      font-size: 3rem;
      span {
        margin-left: 2rem;
      }
      margin: initial;
    }
  }
`

const FullScreenText = props => {
  return <StyledFullScreenText>{props.children}</StyledFullScreenText>
}

export default FullScreenText
